












import {Vue,Component, Prop} from 'vue-property-decorator';
@Component({
    name : 'VisualizarImagen'
})
export default class VisualizarImagen extends Vue
{
    @Prop({type:String,required:true}) imagen!:string;
    
    
}
